/* eslint-disable jsx-a11y/accessible-emoji */
import React from 'react';
import { ReactComponent as TwitterLogo } from '../assets/svg/twitter.svg'
import { ReactComponent as LinkedinLogo } from '../assets/svg/linkedin.svg'
import { ReactComponent as Instagram } from '../assets/svg/instagram.svg'
import { ReactComponent as Email } from '../assets/svg/email.svg'
import { ReactComponent as Graphic } from '../assets/svg/bg.svg'
import { ReactComponent as Js } from '../assets/svg/js.svg'

const Header = () => {
    return (
        <div className="header-v2">
            <div className="hd-fancy">
                <div className="hd-fc-con">
                    <div className="easy-box-1"><Graphic /></div>
                    <div className="easy-box-2"><Graphic /></div>
                </div>
                <div className="overlay-v2">
                    <div className="con-card">
                        <div className="cc-c-head">
                            <div className="impot">
                                <h2>Owusu Appiah Kwabena</h2>
                                <p>Software Engineer</p>
                            </div>
                            <div className="badge">
                                <Js />
                                <div className="h">is my favourite food</div>
                            </div>
                        </div>
                        <div className="disgrace">
                            <div className="ff-h">Get me here</div>
                            <div className="pads">
                                <Pad title="Instagram" icon={<Instagram />} link={'https://www.instagram.com/charlmacarth/'} />
                                <Pad title="LinkedIn" icon={<LinkedinLogo />} link={"https://www.linkedin.com/in/owusu-appiah-kwabena-a4a82213b/"} />
                                <Pad title="Twitter" icon={<TwitterLogo />} link={'https://www.twitter.com/iam_owusu'} />
                                <Pad title="Send email" icon={<Email />} link={'mailto:charliemacarth@gmail.com'} />
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

const Pad = ({ link, icon, title, border }) => {
    return (
        <div title={`${title}`} onClick={() => window.open(link, "_blank")} className={`pad${border ? ' border': ''}`}>{icon}</div>
    )
}

export default Header;
