import React from 'react';
// import ProjectContent from './ProjectContent'
// import MobileFrame from './MobileFrame';
// import V from '../assets/videos/v1.mp4'
// import X1 from '../assets/img/x1.png'
// import X2 from '../assets/img/x2.png'

function Content() {
  // const images = [X1, X2]
  // const topProjects = [
  //   {
  //     name: 'Inteligence Pool',
  //     description: "Originally started as a Coding bootcamp, Intelligence Pool now develops mobile and web applications",
  //     link: 'intelligencepool.com',
  //     subs: [
  //       {
  //         title: "Home",
  //         link: "intelligencepool.com",
  //         description: "Welcome - to Intelligence Pool"
  //       },
  //       {
  //         title: "BurrBs - Web",
  //         link: "burrbs.com",
  //         description: "A social media app that lets users share what's around them, connect with friends, create, explore and join social shows."
  //       },
  //       {
  //         title: "BurrBs - iOS",
  //         link: "apps.apple.com/app/id1584860649",
  //         description: "iOS App for BurrBs"
  //       },
  //       {
  //         title: "BurrBs - Android",
  //         link: "burrbs.s3.us-east-2.amazonaws.com/BurrBs-v1.3.2.apk",
  //         description: "Android App for BurrBs"
  //       },
  //       {
  //         title: "BurrBs - Mini Studio",
  //         link: "studio.burrbs.com",
  //         description: "Mini Web Studio for BurrBs"
  //       },
        
  //       {
  //         title: <span>iPooL Bootcamp</span>,
  //         link: "bootcamp.intelligencepool.com",
  //         description: "Think like a Sofware Engineer, Code like a Pro"
  //       }
  //     ]
  //   },
  //   {
  //     name: 'Ejukate, Inc.',
  //     description: "Ejukate is focusing on speeding up the education industry by solving some of the obvious issues. Class Management, Project and Solutions System, Online Exam Tool, Automatic Grading System and more",
  //     link: 'welcome.ejukate.com',
  //     subs: [
  //       {
  //         title: "Welcome",
  //         link: "welcome.ejukate.com",
  //         description: "Welcome page for Ejukate"
  //       },
  //       {
  //         title: "Web",
  //         link: "ejukate.com",
  //         description: "Web App for the Ejukate platform"
  //       },
  //       {
  //         title: "iOS",
  //         link: "apps.apple.com/app/id1552160526",
  //         description: "iOS App for the Ejukate platform"
  //       },
  //       {
  //         title: "Android",
  //         link: "ejukate-apps.ams3.digitaloceanspaces.com/app.apk",
  //         description: "Android App for the Ejukate platform"
  //       },
  //     ]
  //   },
  //    {
  //     name: 'Adey hia',
  //     description: 'Customers request for items they want to buy without going through a plethora of items, and delivery process is done in few hours.',
  //     link: 'adeyhia.com'
  //   },
  //   // {
  //   //   name: 'Purrps',
  //   //   description: <D />,
  //   //   link: 'purrps.com'
  //   // },
  //   // {
  //   //   name: 'Eeluk',
  //   //   description: "A bio search-engine-like website. Uploaded data is presented to visitors like a search engine",
  //   //   link: 'eeluk.com'
  //   // },
  // ]
 
 
  return (
    <div className='content' id="projects">
      {/* <ProjectContent array={topProjects} /> */}
     
    </div>
  );
}

export default Content;
