import React from 'react';
import Header from './components/header'
import Content from './components/Content'
import './css/main.css'
import About from './components/About';

function App() {
  return (
    <div className='main'>
      <Header />
      <Content />
      <About />
    </div>
  );
}

export default App;
