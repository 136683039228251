import React, { useState, useEffect } from 'react';
import { ReactComponent as ReactLogo } from '../assets/svg/react.svg'
import { ReactComponent as Nodejs } from '../assets/svg/nodejs.svg'
import { ReactComponent as JsLogo } from '../assets/svg/js.svg'
import { ReactComponent as PhpLogo } from '../assets/svg/php.svg'
import { ReactComponent as Java } from '../assets/svg/java.svg'
import { ReactComponent as Html } from '../assets/svg/html5-brands.svg'
import { ReactComponent as CSS } from '../assets/svg/css.svg'
import { ReactComponent as DB } from '../assets/svg/db.svg'
import { ReactComponent as NoSQL } from '../assets/svg/curly-brackets.svg'
import { ReactComponent as Next } from '../assets/svg/next.svg'
import { ReactComponent as Go } from '../assets/svg/golang.svg'
import { ReactComponent as Nest } from '../assets/svg/nestjs.svg'
import { ReactComponent as ElectronJSC } from '../assets/svg/electon.svg'
import { ReactComponent as Python } from '../assets/svg/python-brands.svg'
import { ReactComponent as FastApi } from '../assets/svg/FastAPI.svg'
import ProfilePhoto from '../assets/img/profile.jpg'
import img1 from '../assets/img/ft.jpg'
import lozad from 'lozad'

function About() {
    useEffect(() => {
        var observer = lozad('.lozad', {
            loaded: function (el) {
                // el.parentElement.classList.remove('plch-med');
                el.previousElementSibling.remove()
            }
        });
        observer.observe()
        return () => {
        }
    }, [])
    const [brands] = useState([
        { name: 'Javascript', logo: <JsLogo /> },
        { name: 'Typescript', logo: <JsLogo /> },
        { name: 'Python', logo: <Python /> },
        { name: 'Go', logo: <Go /> },
        { name: 'Relational DBs', logo: <DB /> },
        { name: 'NoSQL DBs', logo: <NoSQL /> },
        { name: 'PHP', logo: <PhpLogo /> },
        { name: 'Java', logo: <Java /> },
        { name: 'HTML', logo: <Html /> },
        { name: 'CSS', logo: <CSS /> },

    ])

    const [frameworks] = useState([
        { name: 'React', logo: <ReactLogo /> },
        { name: 'React Native', logo: <ReactLogo /> },
        { name: 'Nodejs', logo: <Nodejs /> },
        { name: 'Next.js', logo: <Next /> },
        { name: 'Nest.js', logo: <Nest /> },
        { name: 'Electronjs', logo: <ElectronJSC /> },
        { name: 'FastAPI', logo: <FastApi /> },
    ])

    const [tools] = useState([
        { name: 'APIs || Microservices'},
        { name: 'Cloud Computing'},
        { name: 'Git'},
        { name: 'Containerization' },
        { name: 'DevOps' },
        { name: 'Design tools; Figma, XD'},
        { name: 'Product management'},
    ])

    return (
        <div className='about' id="about">
            <div className="abt-content">
                <div className="profile">
                    <img src={ProfilePhoto} alt="" />
                </div>
                <div className="abt-con">
                    <div className="para">
                        <h3>Introduction</h3>
                        <p>
                        Software Engineer with 6+ years of experience in developing user interfaces for web and mobile applications. Solid experience in building and shaping web back-end services and APIs. Experience in all aspects of the software development lifecycle and end-to-end project management, from concept through to development and delivery. Worked in cross-functional teams in a fast-paced, deadline-driven environment to deliver features and to steer project completion. Strong problem-solver that can adapt to new technologies and ideate custom and practical solutions for every project.
                        </p>
                    </div>
                    <div className="para">

                        <h3>Technologies</h3>
                        <h4>Languages | Databases</h4>
                        <div className="flexer">
                            {
                                brands.map((a, i) => {
                                    return (
                                        <div>
                                            <div title={a.name} key={i} className={`rect ${a.name}`}>{a.logo}</div>
                                            <div className="shh">{a.name}</div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <h4>Frameworks | Libraries | RT</h4>
                        <div className="flexer">
                            {
                                frameworks.map((a, i) => {
                                    return (
                                        <div>
                                            <div title={a.name} key={i} className={`rect ${a.name}`}>{a.logo}</div>
                                            <div className="shh">{a.name}</div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <h4>Tools &amp; Concepts</h4>
                        <div className="flexer f2">
                            {
                                tools.map((a, i) => <div key={i} className={`rect r2`}>{a.name}</div>)
                            }
                        </div>
                    </div>
                    <div className="para">
                        <h3>Contact</h3>
                        <ul>
                            <li><a href='https://www.linkedin.com/in/owusu-appiah-kwabena-a4a82213b/'>LinkedIn</a></li>
                            <li><a href='https://twitter.com/xx_icode'>Twitter</a></li>
                            <li><a href='mailto:charliemacarth@gmail.com'>Mail</a></li>
                            <li><a href='callto:+8615623472144'>Phone</a></li>
                        </ul>
                    </div>
                    <h4>#moments</h4>
                    <div className="picture-frames">
                        <div className="pp-l">
                            <div className="loading"></div>
                            <img className="lozad" data-src={img1} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default About;
